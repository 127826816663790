.c-block-menu__title {
    margin: 2em 0 0;
    @include font-size('tiny');
}

.c-block-menu {
    margin: 0;
}

.c-block-menu__link {
    display: inline-block;
    padding: .1em 0;
    color: color('gray');

    @include font-size('subtitle');
    font-weight: 300;

    &:active,
    &:focus,
    &:hover {
        color: color('primary');
    }
}
