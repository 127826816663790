.p-fact {
    
}

.p-fact__inner {
    position: relative;
}

.p-fact__data {
    color: color('gray4');
    @include font-size('tiny2');

    line-height: 1;

    span {
        display: block;

        margin-bottom: 5px;

        @include font-size('subtitle');
    }
}

.p-fact__title {
    color: color('primary');
    @include font-size('post-title');
    font-weight: 300;

    line-height: 1.2;
}

@include media('sm') {
    .p-fact__inner {
        padding-left: 75px;
    }

    .p-fact__data {
        position: absolute;
        top: -8px;
        left: 0;
    
        width: 55px;
        height: 46px;
    
        border-right: 1px solid color('gray4');
    }
}

