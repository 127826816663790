.c-menu {
    margin: 0;
    padding: 0;

    li {
        padding: 5px 0;
    }
}

.c-menu--inline {
    display: flex;

    margin-right: -10px;
    margin-left: -10px;

    li {
        padding: 0 10px;
    }
}

.c-menu--big {
    li {
        padding: 10px 0;
    }

    .c-menu__link {
        @include font-size('medium');
    }
}

.c-menu--small {
    li {
        padding: 5px 0;
    }

    .c-menu__link {
        @include font-size('small');
    }
}

.c-menu__link {
    color: color('gray');
}
