.c-cookies {
    display: flex;
    position: fixed;
    bottom: 15px;
    left: 15px;

    align-items: center;
    justify-content: space-around;

    width: 100%;
    max-width: 640px;

    padding: 10px;

    background: color('gray');
    color: color('background');

    z-index: 14;

    & > div {
        &:first-of-type {
            flex-basis: 520px;

            border-right: 1px solid color('background');
        }
    }
}

.c-cookies__text {
    margin: 0;
    font-size: 9px;
    line-height: 1.1;

    a {
        color: color('background');
        font-size: 9px;
        font-weight: 700;
        text-decoration: underline;
    }
}

.c-cookies__button {
    color: color('background');
    @include font-size('tiny2');

    font-weight: 700;
    line-height: 16px;
    text-align: center;
    text-transform: uppercase;

    white-space: nowrap;

    i {
        padding-bottom: 1px;
        padding-left: 3px;


        font-size: 1.4em;
        line-height: 16px;
        vertical-align: middle;
    }
}
