$colors: (
    primary: #006051,
    error: #f00,
    background: #fff,

    'gray': #8f887f,
    'gray2': #958f87,
    'gray3': #f1f1f1,
    'gray4': #c6c6c6,
    'gray5': #eaeaea,
    'gray6': #f7f7f7,
    'black': #000,

    heading: #006051,
    text: #006051,
    link: #006051,
    link-hover: #006051,
);

/*
$colors: (
    primary: #006051,
    secondary: #f48566,
    error: #f00,
    background: #fff,

    'gray': #8f887f,
    'gray2': #958f87,
    'gray3': #f1f1f1,
    'gray4': #c6c6c6,
    'gray5': #eaeaea,
    'gray6': #f7f7f7,
    'black': #000,

    heading: #262626,
    text: #262626,
    link: #262626,
    link-hover: #006051,
);
*/

