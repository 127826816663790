.c-info {
    display: block;
    position: relative;
    height: 200px;
    padding: 30px 15px;

    transition: color .3s;
    background-size: cover;

    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;

        transition: opacity .3s;

        background: color('primary');
        content: ' ';
        opacity: 0;
        z-index: 0;
    }

    &:hover {
        color: color('background');

        &::after {
            opacity: .7;
        }
    }
}

.c-info__content {
    position: relative;

    z-index: 2;
}

.c-info__text {
    position: relative;
    padding-right: 50px;
    font-weight: 300;

    z-index: 1;

    strong {
        font-weight: 700;
    }
}

.c-info__icon {
    margin-top: -90px;
    text-align: right;
}

@include media ('md') {
    .c-info {
        width: 100%;
        max-width: 640px;
        margin-bottom: -1px;
        margin-left: -155px;
        padding-top: 42px;
        padding-bottom: 42px;
        padding-left: 155px;
        text-align: left;

    }

    .c-info__icon {
        display: block;
        position: absolute;
        top: 50%;
        left: -85px;
        margin-top: 0;

        transform: translateY(-50%);
    }
}

@include media-max ('md') {
    .c-info__text {
        br {
            display: none;
        }
    }
}
