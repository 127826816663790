.c-btn {
    display: inline-block;

    padding: 7px 25px;
    border: 0;
    border-radius: 0;

    background: color('gray2');
    color: color('background');

    @include font-size('tiny');
    font-weight: 700;
    line-height: $base-line-height;

    text-decoration: none;
    cursor: pointer;

    &:active,
    &:focus,
    &:hover {
        background: color('primary');
        color: color('background');
    }
}

.c-btn--white {
    background: color('background');

    color: color('gray2');
}
