.p-promo {
    overflow: hidden;
}

.p-promo--white {
    .p-promo__title,
    .p-promo__text {
        color: color('background');
    }
}

.p-promo__background {
    min-width: 360px;
}


.p-promo__content {
    align-items: flex-start;
    padding: 3em 0;
}

.p-promo__content--mobile-top {
    align-items: flex-start;
}

.p-promo__content--mobile-middle {
    align-items: center;
}

.p-promo__content--mobile-bottom {
    align-items: flex-end;
}

.p-promo__title {
    margin-top: 0;
    margin-bottom: .5em;
}

.p-promo__text {
    max-width: 230px;
    margin: 30px 0;
    font-weight: 300;
}

@include media ('md') {
    .p-promo__content--top {
        align-items: flex-start;
    }

    .p-promo__content--middle {
        align-items: center;
    }

    .p-promo__content--bottom {
        align-items: flex-end;
    }

    .p-promo__content {
        padding: 6em 0;
    }

    .p-promo__text {
        max-width: 390px;
    }
}
