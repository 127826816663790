.c-slider {
    }

.c-slider:not(.slick-initialized) {
    .c-slider__item {
        display: none;

        &:first-of-type {
            display: block;
        }
    }
}

.c-slider__item {
    }
