.c-movies__item {
    position: relative;

    video {
        display: block;
        position: relative;
        width: 100%;
    }

    &.is-paused {
        &::after {
            position: absolute;

            top: 50%;
            left: 50%;

            width: 260px;
            max-width: 30%;
            height: 260px;
            max-height: 30%;

            transform: translate(-50%, -50%);

            background: url('./images/pause-button.png') center center no-repeat;

            background-size: contain;
            content: ' ';
        }
    }
}

.c-movies__links {
    margin: 0;
}

.c-movies__link {
    position: relative;

    background-position: center right;
    background-size: cover;

    &::after {
        display: block;
        padding-bottom: 33%;
        content: '';
    }
}

.c-movies__link-content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    
    width: 100%;
    height: 100%;

    padding: 10px 30px;
}

.c-movies__title {
    margin: 0 0 .3em;

    color: color('primary');
    font-family: font('heading');
    @include font-size('medium');

    line-height: 1.2;

}

.c-movies__link-inner {
    &::before {
        position: absolute;
        top: 0;
        left: 0;
        width: calc(100% - 4px);
        height: calc(100% - 4px);

        border: 2px solid transparent;
        content: ' ';
    }

    &.is-active {
        &::before {
            border: 2px solid color('gray');
        }

    }
}

@include media('lg') {
    .c-movies__links {
        display: flex;

    }

    .c-movies__link {
        width: 33.33%;
    }

    .c-movies__link-inner {
        &::after {
            position: absolute;
            top: -10px;
            left: 50%;
            width: 0;
            height: 0;
    
            border-right: 10px solid transparent;
            border-bottom: 10px solid transparent;
            border-left: 10px solid transparent;
    
    
            content: ' ';
        }

        &.is-active {
            &::after {
                border-bottom: 10px solid color('gray');
            }
        }
    }
}
