@mixin media($name) {
    $width: map-get($grid-breakpoints, $name);
    @if $width {
        @media (min-width: $width) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin media-max($name) {
    $width: map-get($grid-breakpoints, $name);
    @if $width {
        @media (max-width: ($width - 1)) {
            @content;
        }
    } @else {
        @content;
    }
}

@mixin font-size($id) {
    $size-var: map-get($font-sizes, $id);

    @if not $size-var {
        @error 'undefined size name: ' + $id;
    }

    @if type-of($size-var) == 'map' {
        @each $_b, $size in $size-var {
            $b-width: map-get($grid-breakpoints, $_b);

            @if $b-width == 0 {
                font-size: $base-font-size * $size;
            } @else {
                @media (min-width: $b-width) {
                    font-size: $base-font-size * $size;
                }
            }
            

        }

    }

    @else if type-of($size-var) == 'list' {
        @each $_b, $b-width in $grid-breakpoints {
            $i: index(map-keys($grid-breakpoints), $_b);

            @if length($size-var) >= $i {
                @if $b-width == 0 {
                    font-size: $base-font-size * nth($size-var, $i);
                } @else {
                    @media (min-width: $b-width) {
                        font-size: $base-font-size * nth($size-var, $i);
                    }
                }
                
            }
    
        }
    } @else {
        font-size: $base-font-size * $size-var;
    }
    
}
