.c-post-title {
    @include font-size('post-title');
    font-weight: 300;
    line-height: 1.2;
}

.c-post-title--huge {
    @include font-size('subpage-title');
}

.c-post-title--color {
    color: color('primary');
}

.c-post-title--primary {
    font-size: font('heading');
}
