.c-gallery {
    display: block;
}

.c-gallery__item {
    display: block;

    width: 100%;
    height: 100%;
    padding-bottom: 66%;

    transition: transform .4s;

    background-repeat: no-repeat;
    background-position: center center;
    background-size: cover;

    &:hover {
        transform: scale(1.08);
    }
}

@include media("lg") {
    .c-gallery__item {
        padding-bottom: 0;
    }

    .c-gallery {
        display: grid;
    
        grid-template-columns: repeat(8, 1fr);
        grid-gap: 10px;
        grid-auto-rows: minmax(300px, auto);
    }

    .c-gallery__item-wrapper {
        grid-column: 1;
        grid-row: 1;

        overflow: hidden;

        &:nth-child(1) {
            grid-column: 1 / 4;
            grid-row: 1 / 3;
        }

        &:nth-child(2) {
            grid-column: 4 / 6;
            grid-row: 1;
        }

        &:nth-child(3) {
            grid-column: 6 / 9;
            grid-row: 1;
        }

        &:nth-child(4) {
            grid-column: 4 / 7;
            grid-row: 2;
        }

        &:nth-child(5) {
            grid-column: 7 / 9;
            grid-row: 2 / 4;
        }

        &:nth-child(6) {
            grid-column: 1 / 3;
            grid-row: 3;
        }

        &:nth-child(7) {
            grid-column: 3 / 7;
            grid-row: 3;
        }
    }
}
