.p-horeca {
    margin-top: 55px;
}

.p-horeca__header,
.p-horeca__taste,
.p-horeca__water,
.p-horeca__appreciate {
    p {
        font-weight: 300;
    }
}

.p-horeca__header-background {
    min-width: 480px;
}

.p-horeca__header-content {
    align-items: flex-start;
    padding: 4em 0;
}

.p-horeca__header-text {
    max-width: 200px;
}

.p-horeca__taste {
    color: color("background");

    h2 {
        color: color("background");
    }

    p {
        font-weight: 300;
    }
}

.p-horeca__taste-text {
    max-width: 510px;
}

.p-horeca__taste-content {
    align-items: flex-start;
}

.p-horeca__taste-background {
    padding-top: 150px;
    background: color("black");
}

.p-horeca__water {
    text-align: right;
}

.p-horeca__water-content {
    align-items: flex-start;
}

.p-horeca__water-text {
    max-width: 250px;
    margin-left: auto;
}

.p-horeca__appreciate-background {
    min-width: 520px;
}

.p-horeca__appreciate-list {
    margin-top: 10px;
    overflow: hidden;

    margin-right: 120px;

    img {
        max-width: 100%;
    }
}

.p-horeca__appreciate-text {
    max-width: 390px;
}

@include media("md") {
    .p-horeca__taste-background {
        padding-top: 40px;
    }

    .p-horeca__header-text {
        max-width: 340px;
    }
}

@include media("lg") {
    .p-horeca {
        margin-top: 70px;
    }

    .p-horeca__header-text {
        max-width: 500px;
    }

    .p-horeca__taste-background {
        padding-top: 0;
    }

    .p-horeca__water {
        text-align: left;
    }

    .p-horeca__water-content {
        align-items: center;
    }

    .p-horeca__appreciate-list {
        margin-top: 120px;
    }

    .p-horeca__water-text {
        max-width: 510px;
        margin-left: 0;
    }

    .p-horeca__appreciate-text {
        max-width: 510px;
    }
}

@include media("hd") {
    .p-horeca__taste-content {
        align-items: flex-end;
        height: 50%;
    }
}
