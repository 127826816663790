.c-form {
    position: relative;

    max-width: 890px;
    margin: 5em 0 2em;

    br {
        display: none;
    }

    .wpcf7-list-item {
        margin: 0;
    }

    .wpcf7-not-valid-tip {
        margin: .3em 0;
        color: color('text');
        font-weight: 700;
        @include font-size('small');
    }

    .wpcf7-response-output {
        border: 0;
    }

    .wpcf7-form-control-wrap {
        width: 100%;
    }
}

// sass-lint:disable no-vendor-prefixes
.c-form--light {
    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    label,
    textarea {
        color: color('background');
    }

    input[type='email'],
    input[type='number'],
    input[type='password'],
    input[type='search'],
    input[type='tel'],
    input[type='text'],
    textarea {
        border-bottom-color: color('background');
    }

    .selectric-hover {
        .selectric {
            border-color: color('background');

            .button {
                color: color('background');
            }
        }
    }

    .selectric {
        border-color: color('background');

        .label {
            color: color('background');
        }

        .button {
            color: color('background');
        }
    }

    .c-form__checkbox {
        input[type='checkbox'] + span {
            &::before {
                border: 1px solid color('background');
            }

        }
    }

    ::-webkit-input-placeholder {
        color: color('background');
    }

    ::-moz-placeholder {
        color: color('background');
    }

    :-ms-input-placeholder {
        color: color('background');
    }

    :-moz-placeholder {
        color: color('background');
    }
}

.c-form__group {
    display: block;
    position: relative;
    align-items: flex-start;
    margin: 1em 0;

    label {
        display: block;
        flex-basis: 130px;
        flex-shrink: 0;
        margin-top: .3em;
        margin-bottom: .5em;
        @include font-size('normal');
        font-weight: 300;
    }

    & > * {
        display: block;
        width: 100%;
    }
}

.c-form__group--multi {
    display: block;

    & > div {
        display: block;
        align-items: flex-start;
        width: 100%;
        margin: 1em 0;
    }
}

.c-form__submit {
    position: relative;
    text-align: right;

    z-index: 1;

}

.c-form__checkbox {
    position: relative;
    margin-bottom: 8px;
    line-height: 20px;

    .input-error {
        padding-left: 40px;

        p {
            margin: 0;
            color: color('error');
            @include font-size( 'small');
            font-weight: 300;
        }
    }

    input[type='checkbox'] {
        position: absolute;
        left: -5000px;
    }

    input[type='checkbox'] + span {
        display: inline-block;
        position: relative;
        margin-bottom: 5px;
        padding-left: 30px;
        @include font-size( 'small');
        line-height: 15px;

        a {
            @include font-size( 'small');
            font-weight: 700;
        }

        &::after,
        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 15px;
            height: 15px;

            content: ' ';
        }

        &::before {
            border: 1px solid color('text');
        }

        &::after {
            top: 1px;
            left: 1px;

            font-family: 'Font Awesome 5 Free';
            font-size: 10px;
            font-weight: 900;

            text-align: center;
            content: '';
        }

    }

    input[type=checkbox]:checked + span {
        &::after {
            content: '\f00c';
        }
    }

}

@include media ('sm') {
    .c-form__group {
        display: flex;

        label {
            margin-bottom: 0;
        }
    }

    .c-form__group--multi {
        display: block;

        & > div {
            display: flex;
        }
    }
}

@include media ('lg') {

    .c-form__group--multi {
        display: flex;
        justify-content: space-between;

        & > div {
            max-width: 400px;
            margin: 0;
        }
    }

    .c-form__submit {
        .c-form__checkbox + & {
            width: 50%;
            margin-top: -24px;
            margin-left: auto;
        }
    }
    
}
