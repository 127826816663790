.p-mobile-nav {
    display: flex;
    position: fixed;
    top: 0;
    right: calc(-50% - 120px);

    flex-direction: column;
    justify-content: space-between;
    
    width: calc(50% + 120px);
    height: 100%;

    padding: 120px 25px 15px;

    transition: right .4s;
    background: color('gray3');


    &.is-active {
        right: 0;
    }
}

.p-mobile-nav__menu {
    
}

.p-mobile-nav__socials {
    
}

@include media ('lg') {
    .p-mobile-nav {
        display: none;
    }
}

.p-mobile-nav__languages {
    
}
