.c-search-form {
    position: relative;

    .screen-reader-text {
        display: none;
    }

    input[type='text'] {
        min-width: 220px;

        transition: opacity .3s;
        background: transparent;
        opacity: 0;

        &:focus {
            opacity: 1;

            & + button[type='submit'] {
                color: color('primary');
            }
        }
    }

    button[type='submit'] {
        position: absolute;

        right: 0;
        bottom: 5px;
        transition: color .3s;

        border: 0;
        background: transparent;


        color: color('gray');
    }

    &:hover,
    &.is-active {
        input[type='text'] {
            opacity: 1;
        }
    }
}
