.p-footer {
    margin-top: 6em;
}

.p-footer__container {
    position: relative;

    padding-top: 3em;
    padding-bottom: 3em;

    &::before {
        position: absolute;
        top: 0;
        right: 100%;

        width: 1000px;
        height: 100%;
        background: color('gray3');
        content: ' ';
        z-index: 0;
    }

    &::after {
        position: absolute;
        top: 0;
        left: 0;

        width: 81%;
        min-width: 245px;

        height: 100%;
        background: color('gray3');
        content: ' ';
        z-index: 0;
    }
}

.p-footer__content {
    position: relative;
    z-index: 1;
}

.p-footer__socials,
.p-footer__menu {
    margin-bottom: 30px;
}

.p-footer__logo {
    max-width: 160px;
    margin: 1em 0 2em;
}

.p-footer__author {
    margin-top: 4em;
    @include font-size('tiny');

    a {
        @include font-size('tiny');
    }
}

.p-footer__copywrite {
    margin-top: 0;
    @include font-size('tiny');
}



@include media ('hd') {
    .p-footer__container {
        &::after {
            min-width: 1060px;
        }
    }
}
