.c-fact-card {
    position: relative;
    margin-bottom: 80px;

    padding-left: 80px;
}

.c-fact-card--slide {
    margin-bottom: 0;
    margin-left: 5px;
    padding-left: 0;
}

.c-fact-card__data {
    position: absolute;
    top: -8px;
    left: 0;

    width: 55px;
    height: 46px;

    border-right: 1px solid color('gray4');

    color: color('gray4');
    @include font-size('tiny2');

    line-height: 1;

    span {
        display: block;

        margin-bottom: 5px;
        @include font-size('subtitle');
    }
}

.c-fact-card__title {
    margin-top: 0;
    margin-bottom: .6em;

    a {
        color: color('gray');
        @include font-size('subtitle');
        font-weight: 400;

        &:hover {
            color: color('primary');
        }
    }

}

.c-fact-card__preview {
    margin: 2em 0;
    @include font-size('small');
}

.c-fact-card__links {
    margin: 0;
    padding: 0;

    li {
        display: inline-block;
        margin-right: 10px;
    }

    a {
        &:not(.c-btn) {
            color: color('gray');
            @include font-size('small');
            font-weight: 700;
        }
    }
}
