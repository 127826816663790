.p-pieniny {
    margin-top: 55px;
}

.p-pieniny__header,
.p-pieniny__header-title {
    color: color("background");
}

.p-pieniny__header-content {
    align-items: flex-start;

    padding-top: 15%;
}

.p-pieniny__header-title {
    margin: .5em 0;
}

.p-pieniny__header-background {
    min-width: 600px;
}

.p-pieniny__header-text {
    max-width: 480px;
    font-weight: 300;
}

.p-pieniny__dunajec {
    margin-top: -1px;
}

.p-pieniny__dunajec-background {
    min-width: 600px;
}

.p-pieniny__dunajec,
.p-pieniny__dunajec-title {
    color: color("background");
}

.p-pieniny__dunajec-text {
    max-width: 500px;
    font-weight: 300;
}

.p-pieniny__dunajec-content {
    padding-bottom: 10em;
}

.p-pieniny__water-content {
    top: auto;
    bottom: 0;
    height: 50%;
}

.p-pieniny__water-text {
    max-width: 370px;
    font-weight: 300;

}

.p-pieniny__enclave {
    padding-bottom: 380px;
}

.p-pieniny__enclave-content {
    top: auto;
    right: 0;
    bottom: 0;

    left: auto;
    align-items: flex-end;
    width: 100%;
    height: auto;

    padding-bottom: 0;

    background: color("background");
}

.p-pieniny__enclave-text {
    font-weight: 300;
}

.p-pieniny__enclave-box {
    position: relative;

    max-width: 700px;
    padding-left: 2em;

    z-index: 1;
}

.p-pieniny__enclave-subtitle {
    margin: 3em 0 0;

    line-height: 1.2;
}

.p-pieniny__gallery {
    margin-top: 6em;
}

@include media("lg") {
    .p-pieniny {
        margin-top: 70px;
    }

    .p-pieniny__water-content {
        height: 100%;
    }

    .p-pieniny__enclave-content {
        width: 50%;
        min-height: 50%;
    }

    .p-pieniny__enclave {
        padding-bottom: 0;
    }
}
