.c-map {
    height: 450px;

    .gm-fullscreen-control,
    .gm-style-mtc {
        display: none;
    }
}

@include media ('md') {
    .c-map {
        height: 850px;
    }
}
