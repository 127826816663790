.c-languages {
    display: flex;
    flex-direction: column;
    margin: 0;

    li {
        margin: 0 6px;
        border-bottom: 1px solid color('gray3');
    }
}

.c-languages--list {
    flex-direction: row;
    margin: 0 -6px;
}

.c-languages--box {
    height: 30px;

    overflow: hidden;

    li {
        margin: 0;
        padding-right: 6px;
        padding-left: 6px;
        border-bottom: 1px solid color('gray3');
        background: color('background');

        &:last-of-type {
            border-bottom: 0;
        }
    }

    &:hover {
        overflow: visible;
    }
}

.c-languages__link {
    display: inline-block;

    padding: 5px 0;

    img {
        height: 14px;
    }
}

.c-languages__link--pl {
    img {
        border: 1px solid color('error');
    }
}
