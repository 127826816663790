.c-award-box__wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
}

.c-award-box {
    height: 100%;
    min-height: 480px;

    padding: 15px 20px;

    background: color('gray3');

    text-align: center;
}

.c-award-box__title {
    min-height: 45px;

    color: color('primary');
    font-family: font('heading');
    @include font-size('medium');

    font-weight: 400;
}

.c-award-box__text {
    p {
        @include font-size('small');
    }
}


