.c-hospital__wrapper {
    margin-top: 15px;
    margin-bottom: 15px;
}

.c-hospital {
    height: 100%;
    padding: 25px 15px;
    background: color('gray6');

    text-align: center;
}

.c-hospital__title {
    min-height: 90px;
    color: color('primary');
    @include font-size('medium');
    font-family: font('heading');

}

.c-hospital__text {
    @include font-size('small');
}

@include media('lg') {
    .c-hospital__wrapper {
        margin-top: 50px;
        margin-bottom: 50px;
    }
}
