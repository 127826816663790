.c-instagram__photo {
    display: block;
    position: relative;

    img {
        position: relative;
        max-width: 100%;

        z-index: 1;
    }
}

.c-instagram__content {
    position: relative;
    padding: 0 20px;

    z-index: 2;
}

.c-instagram__name {
    color: color('gray');
    @include font-size( 'small');
    font-weight: 700;
}

.c-instagram__text {
    @include font-size( 'small');
}

@include media ('lg') {
    .c-instagram__photo {
        margin-top: -150px;

        z-index: 1;

        &::before {
            position: absolute;
            top: 0;
            left: 0;

            width: 1000px;
            height: 100%;

            background: color('background');

            content: ' ';
            z-index: 0;
        }
    }
}
