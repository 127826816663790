.p-product__header {
    position: relative;
    min-height: 900px;

    padding: 0 0 75px;
}

.p-product__header-background {

    padding-top: 40px;
    background-color: color('gray3');

    img {
        display: block;
    }

    .is-desktop {
        display: none;
    }

    .is-mobile {
        display: block;
        height: 320px;
        margin-left: auto;
    }
}

.p-product__header-image {
    display: flex;
    position: absolute;
    top: 80px;
    left: 0;
    align-items: flex-end;
    width: 210px;

    img {
        max-width: 100%;
    }
}

.p-product__header-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 60px;

    z-index: 1;
}

.p-product__header-back {
    position: absolute;
    top: 50px;
    left: 15px;
}

.p-product__header-links {
    margin: 0;
    padding: 30px 0 0;

    li {
        display: inline-block;
        margin-right: 15px;
    }

    a {
        &:not(.c-btn) {
            color: color('gray');
            @include font-size( 'small');
            font-weight: 400;

            &:hover {
                color: color('primary');
            }
        }
    }
}

.p-product__about {
    position: relative;
    padding: 40px 0 0;
    background: color('gray3');

}

.p-product__about-image {
    img {
        display: block;
        max-width: 100%;
    }
}

.p-product__about-capacity {
    margin-top: 2em;
}

.p-product__about-capacity-title {
    margin-bottom: 0;
    @include font-size( 'small');
    font-weight: 400;
}

.p-product__ingredients {
    position: relative;

    padding: 60px 0;
}

.p-product__ingredients-image {
    display: none;
    position: absolute;
    top: 0;
    right: 0;
}

.p-product__others-content {
    overflow: hidden;

}

.p-product__others-list {
    position: relative;
    margin: 5em 0;
}

@include media ('md') {
    .p-product__header {
        min-height: 700px;
        padding: 95px 0 75px;

    }

    .p-product__header-background {
        position: absolute;

        top: 0;
        left: 0;

        width: 100%;
        height: 100%;

        img {
            position: absolute;

            bottom: 0;
            left: 50%;
            max-width: 70%;
        }

        .is-desktop {
            display: block;
        }

        .is-mobile {
            display: none;
        }
    }

    .p-product__header-image {
        bottom: -120px;
        left: 50%;
        transform: translateX(-50%);
    }

    .p-product__header-content {
        position: relative;

        min-height: 530px;
        margin-top: 0;

    }

    .p-product__header-back {
        position: relative;
        top: auto;
        left: auto;
    }

    .p-product__about {
        margin-top: 150px;
    }

    .p-product__others-list {
        margin: 2em 0;
    }

}

@include media ('lg') {
    .p-product__about {
        min-height: 750px;
        padding: 60px 0;
    }

    .p-product__about-image {
        position: absolute;
        right: 48%;
        bottom: 0;

        width: 52%;

        img {
            max-width: auto;
            max-height: 100%;
            margin-left: auto;
        }
    }

}

@include media ('xl') {
    .p-product__header {
        background-size: auto;
        background-position-x: right;
    }

    .p-product__header-image {
        width: 450px;
    }

    .p-product__ingredients {
        min-height: 850px;
        padding: 80px 0;
    }

    .p-product__ingredients-image {
        display: block;
    }

}
