.c-search-card {
    position: relative;
    margin: 120px 0;
}

.c-search-card__title {
    margin-top: 0;
    margin-bottom: .2em;

    a {
        color: color('gray');
        @include font-size('subtitle');
        font-weight: 400;

        &:hover {
            color: color('primary');
        }
    }

}

.c-search-card__info {
    display: flex;
    margin: 0 -15px 0 -12px;
    padding: 0;

    li {
        padding: 0 15px; 

        @include font-size('tiny');

        border-right: 1px solid color('gray4');

        &:last-of-type {
            border-right-width: 0;
        }
    }
}

.c-search-card__preview {
    margin: 2em 0;
    @include font-size('small');
}
