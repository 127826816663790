.c-choose-item {
    display: block;
    position: relative;

    height: 0;
    min-height: 0;

    padding: 0 30px 52% 80px;

    background-repeat: no-repeat;
    background-position: bottom center;
    background-size: 0;

    &:hover {
        .c-choose-item__hover-content {
            opacity: 1;
        }
    }

}

.c-choose-item__wrapper {
    display: flex;
    flex-wrap: wrap;

    & > a {
        flex-basis: 100%;
        transition: flex-basis .2s;
    }
}

.c-choose-item__mobile-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: 100% auto;

    z-index: -1;
}

.c-choose-item__title {
    max-width: 300px;
    margin: .5em 0;
}

.c-choose-item__before-title {
    margin: 0;
}

.c-choose-item__content {
    margin: 2em 0 0;
}

.c-choose-item__hover-content {
    display: none;
    position: relative;
    z-index: 1;
}

.c-choose-item__text {
    max-width: 220px;
    margin: 2em 0;
    color: color('text');
    font-weight: 300;
}

.c-choose-item__image {
    display: none;
    position: absolute;
    bottom: -65px;
    left: 0;

    max-width: 100%;
}

@include media ('sm') {
    .c-choose-item {
        height: auto;
        min-height: 765px;
        margin-bottom: 85px;
        padding: 65px 30px;
        background-size: auto 100%;
    }

    .c-choose-item__wrapper {
        & > a {
            flex-basis: 50%;
        }
    }

    .c-choose-item__mobile-background {
        width: 0;
        height: 0;
    }

    .c-choose-item__image {
        display: block;
    }

    .c-choose-item__hover-content {
        display: block;
    }
}

@include media ('lg') {
    .c-choose-item__wrapper {
        & > a {
            flex-basis: 25%;
        }
    }
}

@include media ('xl') {
    .c-choose-item__hover-content {
        transition: opacity .3s;
        opacity: 0;
    }
}

@include media ('hd') {
    .c-choose-item__wrapper {
        flex-wrap: nowrap;

        & > a {
            &:hover {
                flex-basis: 33%;
            }
        }
    }
}

@include media ('ehd') {
    .c-choose-item {
        height: 0;
        min-height: 0;
        padding-bottom: 46%;
    }
}
