h1,
h2,
h3,
h4,
h5,
h6 {
    margin: spacing('default') 0;
    color: color('heading');
    line-height: $base-line-height;
}

p {
    margin: spacing('default') 0;
    letter-spacing: -.02em;
    line-height: $base-line-height;
}

