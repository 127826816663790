a {
    color: color('link');
    text-decoration: none;

    @include font-size('default');

    &:active,
    &:focus,
    &:hover {
        color: color('link-hover');
    }
}
