
.c-contact-box {
    margin-bottom: 50px;
}

.c-contact-box__title-wrapper {
    display: flex;
    align-items: flex-end;
    min-height: 70px;

    h2 {
        margin: 0;
    }
}

.c-contact-box__text {
    min-height: 90px;
    margin: 1.6em 0 1.8em;

    @include font-size('small');
    font-weight: 300;
    line-height: 1.8;
}

.c-contact-box__item {
    display: flex;
    align-items: flex-end;
    margin: .7em 0;

    font-weight: 300;
    @include font-size('small');
    line-height: 1;

    white-space: nowrap;

    img {
        display: inline-block;

        padding-right: 5px;

        vertical-align: bottom;
    }
}

.c-contact-box__item-label {
    display: block;
    flex-basis: 80px;
}

.c-contact-box__item-value {
    display: block;
    @include font-size('normal');
    color: color('gray');
}
