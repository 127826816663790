.c-title {
    margin-top: .6em;
    margin-bottom: .6em;
    @include font-size('title');
    font-weight: 300;
}

.c-title--primary {
    font-family: font('heading');
}
