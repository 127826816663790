$grid-breakpoints: ( 
    xs: 0, 
    sm: 576px, 
    md: 768px, 
    lg: 992px, 
    xl: 1200px, 
    hd: 1360px, 
    ehd: 1680px
);

$container-max-widths: ( sm: 540px, md: 720px, lg: 960px, xl: 1140px, hd: 1310px);

$grid-gutter: 30px;
$columns-count: 16;
