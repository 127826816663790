.c-tabs-links {
    display: flex;
    flex-wrap: wrap;
    margin: 1em 0;
    padding: 0;
}

.c-tabs-links__item {
    display: block;

    margin-right: 1em;
    color: color('gray');
    @include font-size('medium');
    font-weight: 300;

    &.is-active,
    &:active,
    &:focus,
    &:hover {
        color: color('gray');
        font-weight: 700;
        letter-spacing: -.05em;
    }
}
