.c-slide__title {
    margin: 0;
    transform: translateY(-50px);
    transition: transform .5s, opacity .6s;
    opacity: 0;

    &.is-active {
        transform: translateY(0);
        opacity: 1;
    }
}

.c-slide__signature {
    position: relative;
    margin: 1em 0;
    transform: translateY(-50px);
    transition: transform .5s, opacity .8s;
    opacity: 0;

    &.is-active {
        transform: translateY(0);
        opacity: 1;
    }

    img {
        max-width: 120px;

    }
}

.c-slide__image {
    position: absolute;
    top: -40px;
    width: 70%;
    transform: translateX(-50px);
    transition: transform .5s, opacity .6s;
    opacity: 0;

    img {
        display: block;
        position: relative;
        max-width: 100%;
        margin: 0 auto;
    }

    &.is-active {
        transform: translateX(0);
        opacity: 1;
    }
}

.c-slide__background {
    position: relative;

    img {
        min-width: 102%;

        transform: translateX(-1%);
    }
}

.c-slide__content {
    &.p-bg-section__content {
        position: relative;

        padding-top: 90px;

        z-index: 7;
    }

}

@include media ('lg') {
    .c-slide__content {
        &.p-bg-section__content {
            position: absolute;
            padding-top: 2em;
        }

    }

    .c-slide__image {
        top: -100px;
        bottom: auto;
        left: 100px;
        max-width: 40%;
    }

    .c-slide__signature {
        img {
            max-width: 100%;
        }
    }
}

@include media ('ehd') {

    .c-slide__image {
        max-width: 50%;
    }

}
