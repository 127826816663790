.o-container {
    width: 100%;
    max-width: 100%;
    margin: 0 auto;
    padding-right: ($grid-gutter / 2);
    padding-left: ($grid-gutter / 2);
}

@each $b, $b-width in $container-max-widths {
    @include media($b) {
        .o-container {
            max-width: $b-width;
        }
    }
}
