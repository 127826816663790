.c-hamburger {
    display: block;
    position: relative;
    width: 25px;
    height: 11px;
    z-index: 22;

    &.is-active {
        .c-hamburger__bar {
            &:nth-of-type(1) {
                transform: rotate(45deg);
            }

            &:nth-of-type(2) {
                opacity: 0;
            }

            &:nth-of-type(3) {
                transform: rotate(-45deg);
            }
        }
    }
}

.c-hamburger__bar {
    display: block;
    width: 100%;
    height: 1px;
    margin-bottom: 3px;
    transform-origin: 25%;
    transition: transform .3s, opacity .2s;
    background: color('gray');

}
