.c-socials {
    display: flex;

    margin: 0 -7px;
}

.c-socials--big {
    .c-socials__link {
        @include font-size('subtitle');
    }
}

.c-socials__link {
    display: inline-block;
    padding: 5px 7px;
    color: color('gray');
}
