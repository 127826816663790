// sass-lint:disable-all

@font-face {
    font-family: 'Bodoni 72';
    src: url('./fonts/BodoniSvtyTwoITCTT-Book.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoITCTT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni 72 Oldstyle';
    src: url('./fonts/BodoniSvtyTwoOSITCTT-Bold.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoOSITCTT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni 72 Oldstyle';
    src: url('./fonts/BodoniSvtyTwoOSITCTT-Book.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoOSITCTT-Book.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni 72 Oldstyle';
    src: url('./fonts/BodoniSvtyTwoOSITCTT-BookIt.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoOSITCTT-BookIt.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Bodoni 72';
    src: url('./fonts/BodoniSvtyTwoITCTT-Bold.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoITCTT-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Bodoni 72';
    src: url('./fonts/BodoniSvtyTwoITCTT-BookIta.woff2') format('woff2'),
        url('./fonts/BodoniSvtyTwoITCTT-BookIta.woff') format('woff');
    font-weight: normal;
    font-style: italic;
}