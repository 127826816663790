input[type='email'],
input[type='number'],
input[type='password'],
input[type='search'],
input[type='tel'],
input[type='text'],
textarea {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 .4em;

    transition: box-shadow .1s;

    border: 0;
    border-bottom: 1px solid color('gray');

    background: transparent;
    @include font-size( 'normal');
    font-weight: 400;
    line-height: 1.5;

    &.error {
        border-color: transparent;
        box-shadow: inset 0 -3px 0 0 transparentize(color(error), .6);
    }
}

textarea {
    height: 150px;
}

label {
    display: block;
    @include font-size( 'normal');

    font-weight: 300;
}

.selectric-hover {
    .selectric {
        border-color: color('gray');

        .button {
            color: color('text');
        }
    }
}

.selectric {
    display: block;
    width: 100%;
    margin: 0;
    padding: 0 0 .4em;

    transition: box-shadow .1s;

    border: 0;
    border-bottom: 1px solid color('gray');

    background: transparent;
    @include font-size( 'normal');
    font-weight: 400;
    line-height: 1.5;

    .label {
        height: 26px;
        margin-left: 0;
        color: color('text');
        @include font-size( 'normal');
        font-weight: 400;

        line-height: 26px;
    }

    .button {
        width: 26px;
        height: 26px;
        background: transparent;
        color: color('text');
        font-family: 'Font Awesome 5 Free';
        font-size: 0;
        line-height: 26px;

        &::after {
            width: 100%;
            height: 100%;
            margin: 0;

            border: 0;
            font-size: 24px;

            font-weight: 900;
            content: '\f107';

        }
    }
}




