.c-ingredients-table {
    margin: 2em 0;

    li {
        &:nth-child(2n - 1) {
            background: color('gray3');
        }

        &:last-of-type {
            background: color('primary');

            color: color('background');
        }
    }
}

.c-ingredients-table__item {
    display: flex;
    justify-content: space-between;

    width: 100%;
    padding: 12px 0;

    font-weight: 300;

    span {
        display: block;
        padding: 0 15px;

        &:last-of-type {
            flex-basis: 120px;
            flex-shrink: 0;
        }
    }
}

@include media ('md') {
    .c-ingredients-table__item {
        span {
            padding: 0 40px;

            &:last-of-type {
                flex-basis: 200px;
            }
        }
    }
}
