.p-404 {
    display: flex;
    align-items: center;
    min-height: calc(100vh - 568px);
    padding: 150px 0;
}

.p-404__content {
    position: relative;
    max-width: 640px;
    margin: 0 auto;
}

.p-404__title {
    @include font-size('medium');
    font-weight: 300;
}

.p-404__text {
    margin-top: .5em;
}

.p-404__image {
    position: absolute;
    top: 95%;
    left: 0;

    img {
        max-width: 100%;
    }
}
