/* Slider */

.slick-slider {
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}

.slick-list {
    display: block;
    margin: 0;
    padding: 0;

    &:focus {
        outline: none;
    }

    &.dragging {
        cursor: pointer;
        cursor: hand;
    }
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.slick-track {
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto;

    &:after,
    &:before {
        content: "";
        display: table;
    }

    &:after {
        clear: both;
    }

    .slick-loading & {
        visibility: hidden;
    }
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;

    [dir="rtl"] & {
        float: right;
    }

    &.slick-loading img {
        display: none;
        height: 0;
    }

    display: none;

    &.dragging img {
        pointer-events: none;
    }

    .slick-initialized & {
        display: block;
    }

    .slick-loading & {
        visibility: hidden;
    }

    .slick-vertical & {
        display: block;
        height: auto;
        border: 1px solid transparent;
    }
}

.slick-arrow.slick-hidden {
    display: none;
}

.slick-arrow {
    display: block;
    position: absolute;
    top: -35px;
    width: 14px;
    height: 15px;
    margin: 0;
    padding: 0;

    transform: translateY(-50%);

    border: 0;
    background: transparent;
    text-indent: -9999px;

    cursor: pointer;

    &::after,
    &::before {
        position: absolute;
        width: 2px;
        height: 10px;

        background: color('gray');
        content: ' ';
    }

    &::before {
        top: 0;
    }

    &::after {
        bottom: 0;
    }
}

.slick-prev {
    right: 40px;

    &::after,
    &::before {
        left: 5px;
    }

    &::before {
        transform: rotate(45deg);
    }

    &::after {
        transform: rotate(-45deg);
    }
}

.slick-next {
    right: 20px;

    &::after,
    &::before {
        right: 5px;
    }

    &::before {
        transform: rotate(-45deg);
    }

    &::after {
        transform: rotate(45deg);
    }
}
