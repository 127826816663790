//sass-lint:disable indentation

$fonts: (
    default: 'Open Sans',
    heading: 'Bodoni 72',
);

$browser-context: 16px;

$base-font-size: 1rem;
$font-sizes: ( 
    'subpage-title': (xs: 3, md: 3.5, lg: 3.7, xl: 4), // 62
    'huge-title': (xs: 2, md: 2.5, lg: 3, xl: 3.9), // 62
    'post-title': (xs: 1.8, md: 2.4, lg: 2.8, xl: 3.2), // 50
    'title': (xs: 1.7, xl: 2, hd: 2.25), // 36
    'subtitle': (xs: 1.5, md: 1.6, lg: 1.8, xl: 2), // 30
    'medium': 1.25, // 20
    default: .875 1 1.125, // 18, 
    'normal': 1,
    small: .875, // 14
    'tiny': .8, // 13
    'tiny2': .75, // 12
    'tiny3': .5,
);

$base-line-height: 1.4;

$base-spacer: 1em;
$spacers: ( 1: 6, 2: 1.5, 3: 1.25, 4: 1, 5: .75, 6: .5, );
