.c-post-content {
    p, 
    ul, 
    ol {
        font-weight: 300;
    }

    strong {
        font-weight: 700;
    }

    ul {
        padding-left: 1em;

        li {
            position: relative;
            margin: 10px 0;
            padding-left: 24px;

            &::after {
                position: absolute;
                top: 0;
                left: 0;

                width: 14px;
                height: 14px;

                border-radius: 50%;
                background: color('primary');
                content: ' ';
            }
        }
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        font-family: font('default');
    }

    h1 {
        @include font-size('title');
    }

    h2 {
        @include font-size('subtitle');
    }

    h3 {
        @include font-size('medium');
    }

    .alignleft {
        margin-right: 20px;
        float: left;
    }

    .alignright {
        margin-left: 20px;
        float: right;
    }
}

.c-post-content--small {
    p, 
    ul, 
    ol,
    a {
        @include font-size('small');
    }
}
