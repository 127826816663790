.o-grid {
    display: flex;
    flex-wrap: wrap;

    margin-right: $grid-gutter / -2;
    margin-left: $grid-gutter / -2;

    &--no-gutters {
        margin-right: 0;
        margin-left: 0;

        & > .o-grid__item {
            padding-right: 0;
            padding-left: 0;
        }
    }

    &--small-gutters {
        margin-right: -5px;
        margin-left: -5px;

        & > .o-grid__item {
            padding-right: 5px;
            padding-left: 5px;
        }
    }
}

.o-grid__item {
    width: 100%;
    min-height: 1px;
    padding-right: ($grid-gutter / 2);
    padding-left: ($grid-gutter / 2);
}

@each $b, $b-width in $grid-breakpoints {
    $_b: if(map-get($grid-breakpoints, $b) == 0, '', '-#{$b}');
    @media (min-width: $b-width) {
        // o-grid--md-align-center
        // o-grid--align-center-md
        .o-grid-#{$_b}-align-start 	{ align-items: flex-start; }
        .o-grid-#{$_b}-align-center 	{ align-items: center; }
        .o-grid-#{$_b}-align-end 		{ align-items: flex-end; }
        
        .o-grid-#{$_b}-justify-start 	{ justify-content: flex-start; }
        .o-grid-#{$_b}-justify-end  	{ justify-content: flex-end; }
        .o-grid-#{$_b}-justify-center  	{ justify-content: center; }
        .o-grid-#{$_b}-justify-around  	{ justify-content: space-around; }
        .o-grid-#{$_b}-justify-between  { justify-content: space-between; }

        .#{$b} {
            flex-basis: 0;
            flex-grow: 1;
            max-width: 100%;
        }

        .#{$b}-one-third {
            flex: 0 0 33.33%;
            max-width: 33.33%;
        }



        @for $i from 1 through $columns-count {
            .#{$b}-#{$i} {
                flex: 0 0 percentage($i/$columns-count);
                max-width: percentage($i/$columns-count);
            }

            .order-#{$b}-#{$i} {
                order: $i;
            }
        }

        @for $i from 1 through ($columns-count - 1) {
            .offset-#{$b}-#{$i} {
                margin-left: percentage($i/$columns-count);
            }
        }
    }
}

