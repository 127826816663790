.p-bg-section {
    position: relative;
}

.p-bg-section__background {
    img {
        display: block;
        width: 100%;
    }
}

.p-bg-section__content {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    align-items: center;
    width: 100%;
    height: 100%;

    padding: 2em 0;
}

.p-bg-section__content--top {
    align-items: flex-start;
}

@include media ('lg') {
    .p-bg-section__background-desktop {
        display: block;
    }

    .p-bg-section__background-mobile {
        display: none;
    }

}
