body {
    background: color('background');

    color: color(text);
    font-family: font('default');
    @include font-size('default');

    overflow: hidden;
}

html {
    overflow-x: hidden;
}
