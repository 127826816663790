// Header
.p-home {
    margin-top: 55px;
}

.p-home__header {
    margin-top: 40px;
    margin-bottom: 2em;
    padding-top: 60px;
}

.p-home__header-bottom {
    display: none;
    position: relative;

    z-index: 1000;
}

// About section
.p-home__about {
    min-height: 980px;
    padding-top: 2.5em;
    overflow: hidden;
}

.p-home__about-background {
    min-width: 600px;
    padding-bottom: 235px;

}

.p-home__about-container {
    position: relative;
    height: 100%;
}

.p-home__about-title {
    max-width: 420px;
}

.p-home__about-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;

    padding-top: 2em;
}

.p-home__about-image {
    display: none;
    position: absolute;
    right: 0;
    bottom: 100px;
    width: 33%;

    img {
        max-width: 100%;
    }
}

// Choose section
.p-home__choose {
    margin-bottom: 6em;
}

.p-home__choose-title {
    margin-top: .6em;
    letter-spacing: -.02em;
}

// Facts section
.p-home__facts {
    margin-top: 55px;
}

.p-home__facts-content {
    margin-bottom: 3em;
    overflow: hidden;
}

.p-home__facts-slider {
    position: relative;
}

@include media ('sm') {
    .p-home__about {
        min-height: 850px;
    }
}

@include media ('lg') {
    .p-home {
        margin-top: 150px;
    }

    .p-home__header {
        margin-top: 0;
        padding-top: 0;
    }

    .p-home__header-bottom {
        display: block;
        min-height: 350px;
    }

    .p-home__about-background {
        min-width: 1440px;
    }

    .p-home__about-content {
        padding-right: 80px;
        padding-left: 80px;
    }

    .p-home__about-image {
        display: block;
    }
}

@include media ('hd') {
    .p-home__choose-content {
        margin-top: -90px;
    }
}
