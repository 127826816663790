.c-huge-title {
    position: relative;
    @include font-size('huge-title');
    color: color('primary');
    font-family: font('heading');
    font-weight: 400;
    line-height: 1;
}

.c-huge-title--quote {

    &::before {
        display: inline-block;
        width: .25em;
        margin-left: -.5em;
        content: '“';
    }

    &::after {
        display: inline-block;
        width: 20px;
        margin-left: -5px;
        content: '”';
    }
}
