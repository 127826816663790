.c-capacity-list {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;

    margin-top: .5em;

    li {
        margin-right: 40px;
    }
}

.c-capacity-list__item {
    display: flex;
    align-items: flex-end;

    width: 80px;

    @include font-size('small');

    img {
        display: block;
        padding-right: 10px;
    }
}
