.p-csr {
    margin-top: 55px;

}

.p-csr__header-container {
    display: flex;
    position: relative;
    align-items: center;

    height: 100%;
}

.p-csr__header {
    margin-bottom: 450px;
}

.p-csr__header-image {
    display: block;

    position: absolute;
    right: 50%;
    bottom: -450px;

    max-height: 400px;
    transform: translateX(50%);
}

.p-csr__header-background {
    min-width: 420px;
}

.p-csr__header-text {
    p {
        @include font-size( 'tiny2');

        font-weight: 300;

        strong {
            font-weight: 700;
        }
    }
}

.p-csr__header-subtitle {
    p {
        font-family: font('heading');
        font-size: 22px;
        line-height: 1.1;

        strong {
            font-size: 36px;
        }

        em {
            font-size: 30px;
            font-style: normal;
        }

    }
}

.p-csr__hospitals {
    margin: 80px 0;
}

.p-csr__hospitals-image {
    margin: 100px 0;
    text-align: center;

    img {
        max-width: 100%;
    }

}

@include media ('md') {
    .p-csr__header {
        margin-top: 70px;
    }

    .p-csr__header-text {
        p {
            @include font-size( 'medium');
        }
    }
}

@include media ('lg') {
    .p-csr__header-background {
        min-width: 1200px;
    }

    .p-csr__header {
        margin-bottom: 0;
    }

    .p-csr__header-image {
        display: none;
        right: -50px;
        bottom: 0;

        max-height: 2000px;
        transform: none;

    }

    .p-csr__header-subtitle {
        p {
            font-size: 32px;

            strong {
                font-size: 56px;
            }

            em {
                font-size: 40px;
            }

        }
    }

    .p-csr__header-image-text {
        display: flex;

        align-items: center;

        & > div {
            padding-right: 15px;
        }
    }
}

@include media ('xl') {
    .p-csr__header-image {
        display: block;
    }
}
