.p-contact {
    & + .p-footer {
        margin-top: 0;
    }
}

.p-contact__list {
    padding-bottom: 40px;
}

.p-contact__form {

    background: color('gray2');
    color: color('background');

    a,
    h2,
    h3,
    h4,
    p {
        color: color('background');
    }
}

.p-contact__form-container {
    position: relative;

    padding-top: 3em;
    padding-bottom: 1em;

}

.p-contact__form-content {
    position: relative;
    z-index: 1;
}

.p-contact__form-title {
    font-family: font('heading');
    @include font-size( 'subtitle');
    font-weight: 400;
}

@include media ('hd') {
    .p-contact__form {
        margin-top: -175px;
        background: transparent;
    }

    .p-contact__form-container {
        &::before {
            position: absolute;
            top: 0;
            right: 100%;

            width: 1000px;
            height: 100%;
            background: color('gray2');
            content: ' ';
            z-index: 0;
        }

        &::after {
            position: absolute;
            top: 0;
            left: 0;

            width: 81%;
            min-width: 245px;

            height: 100%;
            background: color('gray2');
            content: ' ';
            z-index: 0;
        }
    }
}
