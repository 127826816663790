.c-product-card__wrapper {
    margin-top: 20px;
    margin-bottom: 20px;
}

.c-product-card {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;

    margin: 0 5px;
    padding: .1em 0;

    transition: background .4s;

    background: color('gray3');

    &:hover {
        background: color('gray5');
    }
}

.c-product-card__image {
    max-width: 230px;

    img {
        max-width: 100%;
    }
}

.c-product-card__title {
    max-width: 240px;
    min-height: 50px;
    margin: 1em 0;
    color: color('gray');
    @include font-size( 'default');
    font-weight: 300;

    text-align: center;

}


@include media ('lg') {
    .c-product-card {
        height: 100%;
        min-height: 420px;

        margin: 0 15px;
        padding: 10px 40px;

    }
}
