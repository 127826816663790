.p-nav {
    display: flex;
    position: fixed;

    top: 0;
    left: 0;

    align-items: center;
    width: 100%;
    height: 55px;

    transition: top .3s;

    background: color('background');

    z-index: 1001;

    &.is-hidden {
        top: -55px;
    }
}

.p-nav__container {
    position: static;
    z-index: 1;
}

.p-nav__items {
    display: none;
    align-items: center;
    justify-content: space-between;
}

.p-nav__logo {
    margin: 7px 0;
    text-align: center;

    img {
        height: 34px;
    }
}

.p-nav__menu {
    display: none;
}

.p-nav__hamburger {
    margin-left: auto;
}

@include media ('lg') {
    .p-nav {
        height: 70px;
        box-shadow: 0 10px 60px 5px transparentize(color('gray'), .9);
    }

    .p-nav__items {
        display: flex;
    }

    .p-nav__menu {
        display: flex;
    }

    .p-nav__hamburger {
        display: none;
    }

    .p-nav__logo {
        position: relative;
        top: auto;
        left: auto;

        transform: 0;

        img {
            height: 54px;
        }
    }

    .p-nav--home {
        position: absolute;

        height: 150px;
        background: transparent;
        box-shadow: none;

        .p-nav__logo {
            position: absolute;
            top: 5px;
            left: 50%;
            transform: translateX(-50%);

            img {
                height: auto;
            }
        }

        .p-nav__menu {
            display: none;
        }
    }
}

.p-nav__socials {
    
}
