.c-sitemap {
    h2 {
        margin-top: 2em;
        color: color('gray');
        @include font-size('default');
        font-weight: 400;
    }

    a {
        color: color('gray');
        @include font-size('small');
    }

    ul {
        position: relative;

        &::before {
            position: absolute;
            top: 0;
            left: 0;
            width: 1px;
            height: 100%;

            background: color('gray4');
            content: ' ';
        }

        li {
            padding-left: 12px;

            &::after {
                content: none;
            }
        }
    }
}
