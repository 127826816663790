.p-about {
    margin-top: 55px;

    & + .p-footer {
        margin-top: 0;
    }
}

.p-about__header {
    margin-bottom: 50px;
}

.p-about__header-background {
    min-height: 460px;
}

.p-about__header-title {
    margin-bottom: 40px;
    color: color('background');
}

.p-about__header-subtitle {
    margin-bottom: 100px;
    color: color('background');
    font-weight: 700;
}

.p-about__header-text {
    font-weight: 300;
}

.p-about__taste {
    margin-bottom: 120px;
}

.p-about__taste-box {
    padding: 0 15px;
    color: color('background');
}

.p-about__taste-content {
    align-items: flex-end;
}

.p-about__taste-title {
    color: color('background');
}

.p-about__taste-text {
    max-width: 440px;

    p {
        font-weight: 300;
    }
}

.p-about__awards {
    margin-bottom: 50px;
}

.p-about__awards-title {
    margin: 2em 0;
}

.p-about__about {
    margin-bottom: 280px;
}

.p-about__about,
.p-about__about-title {
    color: color('background');
}

.p-about__about-text {
    max-width: 440px;

    p {
        font-weight: 300;
    }
}

.p-about__about-content {
    align-items: flex-end;
    padding: 6em 0;
}

.p-about__about-container {
    position: relative;
}

.p-about__about-image {
    position: absolute;
    right: 0;
    bottom: -380px;
    width: 100%;

    text-align: right;

    img {
        max-width: 90%;
        max-height: 350px;
    }
}

.p-about__what-title {
    margin: 2em 0;
}

.p-about__quote {
    display: flex;
    position: relative;

    align-items: flex-end;
    min-height: 650px;

    padding-bottom: 100px;
}

.p-about__quote-content {
    position: relative;
    max-width: 650px;
    margin: 0 auto;
    padding-top: 100px;
    padding-left: 100px;

    z-index: 2;
}

.p-about__quote-product {
    position: absolute;
    bottom: -50px;
    left: -50px;
}

.p-about__quote-text {
    margin-bottom: 0;
}

.p-about__quote-image {
    position: absolute;

    bottom: 0;
    left: 0;

    img {
        display: block;

        max-width: 100%;
    }
}

@include media ('sm') {
    .p-about__taste-box {
        padding: 90px 60px;
    }

    

    .p-about__quote-product {
        left: -100px;
    }

    .p-about__quote-image {
        right: 0;
        bottom: 0;
        left: auto;

        img {
            max-width: 50%;
            margin-left: auto;
        }
    }
    
}

@include media ('md') {
    .p-about__header {
        margin-bottom: 70px;
    }

    .p-about__header-title {
        margin-bottom: 80px;
    }

    .p-about__header-subtitle {
        margin-bottom: 100px;
    }

    .p-about__about-image {
        bottom: -220px;
        width: 50%;
    }

    .p-about__quote {
        min-height: 850px;

        padding-bottom: 150px;
    }

    .p-about__quote-image {
        bottom: 0;
        left: 15%;

        img {
            max-width: 100%;
            margin-left: 0;
        }
    }

}

@include media ('lg') {
    .p-about {
        margin-top: 70px;
    }

    .p-about__taste-box {
        padding: 30px 10px;
    }

    .p-about__taste-content {
        align-items: center;
    }

    .p-about__about {
        margin-bottom: 150px;
    }

    .p-about__about-image {
        bottom: -220px;

        img {
            max-height: 100%;
        }
    }

    .p-about__quote-image {
        left: 35%;
    }
}

@include media ('xl') {
    .p-about__taste-box {
        padding: 30px 60px;
    }

    .p-about__quote-image {
        left: 45%;
    }

}
