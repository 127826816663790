.c-about-info__wrapper {
    margin-top: 120px;
    margin-bottom: 20px;
}

.c-about-info {
    position: relative;
    height: 100%;
    padding: 110px 5px 20px;
    background: color('gray3');
    text-align: center;

}

.c-about-info__icon {
    position: absolute;
    top: -100px;
    left: 0;

    width: 100%;
    height: 230px;

    text-align: center;
}

.c-about-info__title {
    color: color('primary');
    font-family: font('heading');
    @include font-size('medium');

    font-weight: 400;
}

.c-about-info__text {
    p {
        @include font-size('small');
    }
}
